<template>
  <div>
    <overlay-loader overlay :loading="initLoading"></overlay-loader>
    <v-card>
      <div>
        <v-card-title v-if="!isSold" class="headline accent">
          Забронировано
        </v-card-title>
        <v-card-title v-else>
          <loading-text :text="textToShow" class="text-h5"></loading-text>
          <v-progress-linear indeterminate color="yellow darken-2"></v-progress-linear>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-row class="text-h4 font-weight-bold mt-10">Товары</v-row>
                <v-row>
                  <v-divider></v-divider>
                </v-row>
                <v-row class="text-h6 mt-5">
                  <v-col cols="5"> Билеты x {{ ticketsCount }}</v-col>
                  <v-col class="text-right text-h6 font-weight-bold">
                    {{ value.cost.total + " Руб" }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-divider></v-divider>
                </v-row>
                <v-row class="text-h4 font-weight-bold mt-5">
                  <v-col cols="4">Итог</v-col>
                  <v-col align="end">{{ value.cost.total + " Руб" }}</v-col>
                </v-row>

                <v-row justify="end" class="mt-10">
                  <v-spacer></v-spacer>
                  <v-btn text large color="orange" :disabled="confirmLoading" @click="cancel()">
                    Отмена
                  </v-btn>
                  <v-btn dark elevation="5" :loading="confirmLoading" color="orange" large @click="payment">
                    Оплатить
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import OverlayLoader from "@/components/UI/OverlayLoader.vue";
import LoadingText from "@/components/UI/LoadingText.vue";
export default {
  name: "PurchaseSummary",
  components: { OverlayLoader, LoadingText },
  props: {
    value: {
      type: Object,
      require: true,
    },
    ticketsCount: {
      type: Number,
      require: true,
    },
    email: {
      type: String,
      require: true,
    },
    phone: {
      type: String,
      require: true,
    }
  },
  data() {
    return {
      paymentUrl: "",
      initLoading: true,
      confirmLoading: false,
      isSold: false,
      textToShow: "",
      buyStep: 0,
      pdfUrl: "",
      temp1: null,
      temp2: null,
      temp3: null,
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel", false);
    },
    async payment() {
      try {
        this.isSold = true;
        this.textToShow = "Идет оформление билета";
        console.warn("Отправка 1");
        // let resp1 = await this.$axios.post(
        //     `/Order/Buy?data=${this.value.workId}`
        // );
        console.log(this.value)
        console.log(this.value.workId)
        let req = {
          OrderNumber: this.value.workId,
          Amount: this.value.cost.total,
          // dev
          SuccessUrl: `https://bpo.travel/show-receipt?order=${this.value.workId}`,
          FailUrl: `https://bpo.travel/show-receipt?order=${this.value.workId}&error=true`,
          // RetUrl: `http://localhost:8080/show-receipt?orderNumber=${this.value.workId}`,
          // FailUrl: `http://localhost:8080/show-receipt?orderNumber=${this.value.workId}${this.paymentUrl}`,
          Email: this.email,
          Phone: this.phone,

        };
        let resp1 = await this.$axios.post(`/Payment/Acquiring/Create`, req).then(response => {
          const data = response.data;
          this.paymentUrl = data.url
          console.log(data)
          console.log(this.paymentUrl)
        })
        this.temp1 = resp1;
        // this.textToShow = "Получение маршрут-квитанции";
        // console.warn("Отправка 2");
        // let resp2 = await this.$axios.get(
        //     `/Data/Pdf/Get?data=${resp1.data.workId}`
        // );
        // this.pdfUrl = resp2.data;
      } finally {
        this.isSold = false;
      }
      // Same tab
      await window.open(this.paymentUrl, "_self");
      // New Tab
      // await window.open(this.paymentUrl);
    },
    // async SendTicketToPassenger() {
    //   let req = {
    //     query: {
    //       emailTo: this.email,
    //       message: "Билет успешно выписан",
    //       subject: "string",
    //     },
    //   };
    //   try {
    //     let resp = await this.$axios.post(`/api/Other/Mail/Send`, req);
    //     return resp.data;
    //   } catch (e) {
    //     console.log("SendTicketToPassenger - Error");
    //     this.$store.state.error = {
    //       show: true,
    //       value: {
    //         Code: e.response.status,
    //         Message: e.response.data.Message,
    //       },
    //     };
    //     return null;
    //   }
    // },
  },
};
</script>

<style></style>
