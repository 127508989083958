<template>
  <v-row v-if="brands && brands.length > 0" justify="center">
    <v-col
        v-if="!$vuetify.breakpoint.smAndDown"
        cols="12"
        class="text-right py-1"
    >
      <v-btn
          icon
          class="ma-1"
          @click="mdView = 12"
          :color="mdView == 12 ? 'orange' : 'default'"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-btn
          icon
          class="ma-1"
          @click="mdView = 4"
          :color="mdView == 4 ? 'orange' : 'default'"
      >
        <v-icon>mdi-view-grid</v-icon>
      </v-btn>
    </v-col>

    <v-col
        v-for="brand in brands"
        :key="brand.id"
        cols="12"
        :md="mdView"
        class="pt-0"
    >
      <brand-tariff
          :brand="brand"
          :brandPriceLabel="getPriceLabel(brand)"
          :isSelected="brand.id === current.id"
          @select="selectBrand"
      ></brand-tariff>

    </v-col>
  </v-row>
  <v-row v-else>
    {{
      loadingData
          ? "Загружаем данные..."
          : "Ошибка! Не найдено сведений о тарифе"
    }}</v-row
  >
</template>

<script>


import BrandTariff from "@/components/Buy/BrandTariff";
export default {
  name: "BrandTariffsList",
  components: {BrandTariff  },
  props: {
    id: {
      type: String,
      require: true,
    },
    provider: {
      type: String,
      require: true,
    },
    value: {
      type: Object,
      require: true,
    },
  },
  model: {
    prop: "value",
    event: "change-value",
  },
  data() {
    return {
      current: null,
      brands: null,
      loadingData: true,
      mdView: 4,
    };
  },
  async mounted() {
    try {
      this.brands = await this.getTariffs(this.id, this.provider);
      this.current = [...this.brands].sort((a, b) => {
        return a.price.total - b.price.total;
      })[0];
    } finally {
      this.loadingData = false;
    }
  },
  watch: {
    current(val) {
      this.$emit("change-value", val);
    },
  },
  methods: {
    selectBrand(brand) {
      this.current = brand;
    },
    getPriceLabel(checkingBrand) {
      const selectedBrandPrice = this.brands.find(
          (e) => e.id === this.current.id
      ).price.total;
      const checkingBrandPrice = checkingBrand.price.total;
      const resultPriceLabel = checkingBrandPrice - selectedBrandPrice;
      return resultPriceLabel === 0
          ? "Выбрано (" + checkingBrand.price.total + ")"
          : resultPriceLabel > 0
              ? `+${resultPriceLabel} RUB`
              : `${resultPriceLabel} RUB`;
    },
    async getTariffs(id, provider) {
      let req = {
        id: id,
        provider: provider,
      };
      try {
        console.warn(req);
        let resp = await this.$axios.post("/Order/Fare", req);
        return resp.data;
      } catch (e) {
        console.warn(e.response)
      }
    },
  },
};
</script>

<style style></style>
