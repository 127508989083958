<template>
  <v-container>
    <overlay-loader overlay :loading="initLoading"></overlay-loader>

    <v-row v-if="!initLoading" justify="center">
      <v-col cols="12" md="12" order="0">
        <v-stepper outlined vertical v-model="stepperStep">
          <!--#region Step 1 -->
          <v-stepper-step color="orange" :complete="stepperStep > 1" step="1">
            Тариф
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-card class="pa-5 mb-3">
              <brand-tariffs :id="id" :provider="provider" v-model="selectedTariff"></brand-tariffs>
            </v-card>

            <v-btn @click="stepperStep++" color="orange" class="ma-2" :disabled="selectedTariff === null">
              Далее
            </v-btn>
          </v-stepper-content>
          <!--#endregion -->
          <!--#region Step 2 -->
          <v-stepper-step color="orange" :complete="stepperStep > 2" step="2">
            Данные пассажиров
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-card rounded class="pt-3 px-3 mb-7">
              <v-row justify="center">
                <v-tabs class="mt-5" center-active grow color="orange" :show-arrows="true">
                  <v-tab v-for="(passenger, n) in passengers" :key="n">
                    <span>
                      {{
                        passenger.FirstName
                        ? passenger.FirstName
                        : n + 1 + " Пассажир "
                      }}
                    </span>
                  </v-tab>
                  <v-tab-item v-for="(passenger, n) in passengers" :key="n">
                    <passenger-info :countriesList="countries" :docs="docTypes" v-model="passengers[n]"
                      @updateValid="onValidUpdate($event, n)">
                    </passenger-info>
                  </v-tab-item>
                </v-tabs>
              </v-row>
              <v-form ref="contactInfo" v-model="contactInfoValid">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field outlined color="orange" min="2" label="Телефон" type="phone" v-model="phone" required
                      :rules="phoneRule" @click:append="phone = $store.state.phone">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field outlined color="orange" min="2" label="Email" type="email" v-model="email" required
                      :rules="emailRule">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>

              <v-row>
                <v-checkbox v-model="terms1" color="orange" :ripple="false">
                  <template v-slot:label>
                    <div>
                      Я согласен (-на)
                      <a href=/legalInformation v-on="on" @click.stop target="_blank"> обработку персональных данных
                      </a>
                    </div>
                  </template>
                </v-checkbox>
              </v-row>

            </v-card>
            <v-btn color="orange" @click="stepperStep++" class="mx-2" :disabled="!comparer">
              Далее
            </v-btn>

            <v-btn @click="stepperStep--" text class="mx-2"> К тарифам</v-btn>
          </v-stepper-content>
          <!--#endregion -->
          <!--#region Step 3 -->
          <v-stepper-step color="orange" :complete="stepperStep > 3" step="3">
            Бронирование
          </v-stepper-step>

          <v-stepper-content step="3">
            <reservation-summary v-if="selectedTariff" :value="selectedTariff" :displayCount="passCount">
            </reservation-summary>
            <row>
              <v-checkbox v-model="terms2" color="orange" :ripple="false">
                <template v-slot:label>
                  <div>
                    Я согласен (-на)

                    <a href=/legal v-on="on" @click.stop target="_blank"> с условиями оферты и правилами применения
                      тарифа </a>
                  </div>
                </template>
              </v-checkbox>
            </row>
            <row>
              <v-checkbox v-model="terms3" color="orange" :ripple="false">
                <template v-slot:label>
                  <div>
                    Я ознакомлен (-а) c
                    <a href="https://www.tch.ru/ru-ru/Airlines/Sales-management/Documents/addinf.pdf" v-on="on"
                      @click.stop target="_blank"> дополнительной информацией
                    </a>
                  </div>
                </template>
              </v-checkbox>
            </row>
            <div class=" mt-5">
              <v-btn color="orange" class="mx-2" elevation="0" :loading="isLoading" @click="Booking"
                :disabled="!(terms2 && terms3)">
                Бронировать
              </v-btn>
              <v-btn @click="stepperStep--" text class="mx-2">
                К пассажирам
              </v-btn>
            </div>
          </v-stepper-content>
          <!--#endregion Step 3 -->
        </v-stepper>
      </v-col>
    </v-row>

    <!--show cart-->
    <div v-if="payDialog" class="text-center">
      <v-dialog persistent width="50%" v-model="payDialog">
        <purchase-summary :value="bookInfo" :ticketsCount="passCount" :email="email" :phone="phone"
          @cancel="(val) => (payDialog = val)">
        </purchase-summary>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import BrandTariffs from "@/components/Buy/BrandTariffList.vue";
import OverlayLoader from "@/components/UI/OverlayLoader.vue";
import PassengerInfo from "@/components/Book/PassengerInfo.vue";
import PurchaseSummary from "@/components/Buy/PurchaseSummary.vue";
import ReservationSummary from "@/components/Buy/ReservationSummary.vue";
import ApiCalls from "@/mixins/booking/ApiCalls.js";
import DateFormat from "@/mixins/DateFormat.js";
import Validators from "@/mixins/Validators.js";
import { VueMaskDirective } from "v-mask";
import PassengerHelper from "@/mixins/booking/PassengerHelper.js";

export default {
  name: "BuyTicket",
  directives: { mask: VueMaskDirective },
  components: {
    PassengerInfo,
    OverlayLoader,
    BrandTariffs,
    ReservationSummary,
    PurchaseSummary,
  },
  mixins: [DateFormat, PassengerHelper, Validators, ApiCalls],
  props: {
    adt: { type: Number, required: true },
    cnn: { type: Number, required: true },
    inf: { type: Number, required: true },
    provider: { type: String, required: true },
    id: { type: String, required: true },
  },
  data() {
    return {
      terms1: false,
      terms2: false,
      terms3: false,
      initLoading: true,
      stepperStep: 1,
      selectedTariff: null,
      contactInfoValid: false,
      validForms: [],
      bookInfo: null,
      error: false,
      payDialog: false,
      regnum: null,
      surname: null,
      docTypes: [],
      passengers: [],
      countries: [],
      isLoading: false,
      insurances: null,
      email:
        process.env.NODE_ENV === "development" ? "b.bondarev@bpo.travel" : "",
      phone: process.env.NODE_ENV === "development" ? "+79532720039" : "",
    };
  },
  async mounted() {
    this.initLoading = true;
    this.countries = await this.getCountries();
    console.warn(this.adt);
    console.warn(this.cnn);
    console.warn(this.inf);
    this.passengers = this.generateModels(this.adt, this.cnn, this.inf);
    this.initLoading = false;
  },
  computed: {
    comparer() {
      return this.valid && this.contactInfoValid && this.terms1;
    },
    valid() {
      return this.validForms.every((e) => e);
    },
    passCount() {
      return Number(this.adt) + Number(this.cnn) + Number(this.inf);
    },
  },
  methods: {
    async getCountries() {
      try {
        let resp = await this.$axios.get(`/Data/Countries/Get`);
        return resp.data.map((el) => ({
          text: el.nameRu ? el.nameRu : el.nameEn,
          value: el.codeEn ? el.codeEn : el.codeRu,
        }));
      } catch (e) {
        console.log("getCountries - Error");
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        };
        return;
      }
    },
    async Booking() {
      this.isLoading = true;
      try {
        let req = this.BookRequest(
          this.selectedTariff.id,
          this.passengers,
          this.phone,
          this.email,
          this.provider,
          this.selectedTariff
        );
        console.warn(req);
        let resp = await this.$axios.post(`/Order/Book`, req);
        console.log(resp);
        this.SendMailRequest(
          resp.data.contactInfo.emailAddress,
          resp.data.workId
        );
        this.bookInfo = resp.data;
        this.payDialog = true;
      } catch (e) {
        this.error = true;
        console.log("Purchase - Error");
        this.$store.state.error = {
          show: true,
          value: {
            Code: e.response.status,
            Message: e.response.data.Message,
          },
        };
      } finally {
        this.isLoading = false;
      }
    },
    async SendMailRequest(email, id) {
      console.log("");
      let mess = `<div style="display: flex; justify-content: center;"> <h1 style="text-align: center;">Билет №${id} забронирован.</h1> </div> <div style="display: flex; justify-content: center;"> <h4>Необходимо оплатить бронь</h4> </div> <div style="display: flex; justify-content: center;"> <a href="https://${window.location.host}/booked-ticket?id=${id}" style="font-weight: 600;color:white; font-size: 21px; display:flex;align-items: center;justify-content: center; text-decoration:none; background-color: rgb(255,152,0); width: 200px; height: 50px; border-radius: 25px;">Оплатить</a> </div>`;
      let req = {
        emailTo: email,
        message: mess,
        subject: `BPO Travel БРОНЬ `,
        files: [],
      };
      try {
        await this.$axios.post(`/Other/Mail/Send`, req);
      } catch (e) {
        console.warn(e.response);
      }
    },
    BookRequest(id, passengers, phone, email, provider, selectedTariff) {
      return {
        OfferId: id,
        Passengers: passengers.map((e) => ({
          Id: e.Id,
          Birthday: e.Birthday,
          GenderCode: e.GenderCode,
          TypeCode: e.TypeCode,
          Surname: e.Surname,
          FirstName: e.FirstName,
          MiddleName: e.MiddleName,
          IdentityDoc: {
            Surname: e.Surname,
            DocNumber: e.DocNumber,
            TypeCode: e.TypeCodeDoc,
            IssueCountryCode: e.IssueCountryCode,
            IssueDate: e.IssueDate,
            ExpiryDate: e.ExpiryDate,
          },
        })),
        Contacts: [
          {
            EmailAddress: email,
            Phone: phone,
          },
        ],
        Provider: provider,
        ExtraJSON: JSON.stringify(selectedTariff.extra),
        // ExtraJSON: JSON.stringify(selectedTariff),
      };
    },

    onValidUpdate(data, n) {
      this.validForms.splice(n, 1, data);
    },
  },
};
</script>

<style scoped>
.localfix .v-slide-group__prev.v-slide-group__prev--disabled,
.localfix .v-slide-group__next.v-slide-group__next--disabled {
  display: none !important;
}
</style>
