<template>
  <label> {{ text + dots }} </label>
</template>

<script>
export default {
  name: "LoadingText",
  props: {
    textSize: {
      type: Number,
      default: 20,
    },
    text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dots: ".",
      count: 0,
    };
  },
  mounted: function() {
    var _this = this;
    this.timer = setInterval(function() {
      _this.dots = _this.dots.length < 3 ? _this.dots + "." : ".";
    }, 500);
  },
};
</script>

<style></style>
