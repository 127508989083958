<template>
  <v-card class="pt-5 px-5 mb-12">
    <span class="text-h3 mt-10">Товары</span>
    <v-divider></v-divider>
    <v-row class="text-h5 mt-5">
      <v-col cols="12" md="6"> Билеты x {{ displayCount }}</v-col>
      <v-col cols="12" md="6" align="end">{{
        value.price.total + "Руб"
      }}</v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="text-h5 mt-5">
      <v-col cols="12" md="6">Итог</v-col>
      <v-col cols="12" md="6" align="end">{{
        value.price.total + "Руб"
      }}</v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      require: true,
    },
    displayCount: {
      type: Number,
      require: true,
    },
    unavailableServices: Array,
    default: [],
  },
};
</script>

<style></style>
